@import "colors.less";
@import "variables.less";
@import "shell.less";

@font-face {
    font-family: SegoeMDL2;
    src: url(../fonts/segmdl2.ttf);
}

html, body, div#app {
    height: 100%;
}

/* Common */
.font-icon {
    font-family: "Segoe MDL2 Assets", SegoeMDL2;
}

.font-size-10px {
    font-size: @font-size-10px;
}

.position-relative {
    position: relative;
}

.text-align-left {
    text-align: left;
}
/* */

/* Buttons */
.btn-background-transparent {
    background: transparent;
    border: 0px;
}

.btn-background-gray {
    .btn-background-transparent;
    background: @gray-light-lighter;
    color: black;

    &:hover {
        background: @gray-lighter;
        color: black;
    }

    &:active {
        background: @gray-light-lighter;
        color: black;
    }
}

.btn-background-app {
    .btn-background-transparent;
    background: @app-color;
    color: white;

    &:hover {
        background: @app-color-light;
        color: white;
    }

    &:active {
        background: @app-color-dark;
        color: white;
    }
}

.btn-background-blue {
    .btn-background-transparent;
    background: @blue-color;
    color: white;

    &:hover {
        background: @blue-color-light;
        color: white;
    }

    &:active {
        background: @blue-color-dark;
        color: white;
    }
}

.btn-background-red {
    .btn-background-transparent;
    background: @red-color;
    color: white;

    &:hover {
        background: @red-color-light;
        color: white;
    }

    &:active {
        background: @red-color-dark;
        color: white;
    }
}

.btn-background-transparent-red {
    &:hover {
        background: @red-color-light;
        color: white;
    }

    &:active {
        background: @red-color-dark;
        color: white;
    }
}
/* */

/* Pages */

.page-tab {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
    background-color: white;
}
/* */

/* Pivot */

.ms-Pivot {
    z-index: 1000;
}

.pivot-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
}

.pivot-wrapper > div {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
}

.pivot-wrapper > div > div[role=tabpanel] {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
}

.page-header-content {
    flex: 0 1 auto;
    order: -1;
}

.page-footer-content {
    flex: 0 1 auto;
    order: 9999;
}


/* */

/* Items */

.btn-with-icon-item-page,
.btn-with-icon-item-page.ms-Button {
    .btn-background-transparent;
    padding: 4px 8px 4px 4px;
    position: relative;
    left: -4px;
    font-weight: 600;

    &:hover {
        background: @gray-lighter;
        color: initial;
    }

    &:active {
        background: @gray-light-lighter;
        color: initial;
    }
}

.btn-with-icon-item-page-icon {
    .font-icon;
    color: white;
    background: @gray;
    font-size: @font-size-12px;
    font-weight: initial;
    padding: 3px 3px 5px 5px;
    margin-right: 4px;
    width: 22px;
    height: 22px;
    position: relative;
}

.icon-btn-circle-card,
.icon-btn-circle-card.ms-Button {
    .btn-background-gray;
    height: 48px;
    width: 48px;
    border-radius: 50%;
}

.icon-btn-circle-card i {
    font-size: 24px;
}

.icon-btn-circle-card-option {
    .btn-background-gray;
    background: @gray-light;
    color: white;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin: 0px;
    padding: 0px;
    position: absolute;
    bottom: -8px;
    right: -8px;
}

.icon-btn-circle-card-option i {
    font-size: @font-size-14px;
}

.icon-btn-circle-card-option.red {
    .icon-btn-circle-card-option;
    .btn-background-transparent-red;
}

.text-field-new-item-with-button {
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

    .text-field-new-item-with-button .text-field-new-item {
        flex: 1;
    }
/* */

/* Start End Duration Date Time With Accuracy Card */
.start-end-duration-date {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: stretch;
    max-width: 400px;
}

.start-end-duration-date .btn-start-end-date {
    .btn-background-transparent;
    padding: 0px;
    margin: 0px;
    height: auto;
}

.start-end-duration-date .btn-duration {
    .btn-background-transparent;
    flex: 0 1 100%;
    height: auto;

    &:hover {
        background: transparent;
        color: @app-color;
    }

    &:active {
        background: transparent;
        color: @app-color-dark;
    }
}

.start-end-duration-date .btn-duration div:first-child {
    margin-bottom: -4px;
    flex: 1 1 auto;
}

.start-end-duration-date .btn-duration progress {
    background-color: @gray-lighter;
    color: @app-color;
    border-width: 0px;
}

.start-end-duration-date .btn-duration progress[value] {
    width: 100%;
    height: 6px;
    -moz-appearance: none;
    -webkit-appearance: none;
}

.start-end-duration-date .btn-duration progress[value]::-moz-progress-bar {
    background-color: @gray-lighter;
}

.start-end-duration-date .btn-duration progress[value]::-webkit-progress-bar {
    background-color: @gray-lighter;
}

.start-end-duration-date .btn-duration progress[value]::-webkit-progress-value {
    background-color: @app-color;
}

.start-end-duration-date .btn-duration .lock {
    position: relative;
    margin-left: 4px;
    top: 1px;
}

    .start-end-duration-date .duration {
        font-weight: bold;
    }

    .start-end-date-card {
        position: relative;
    }

.start-end-date-card .lock {
    position: absolute;
    right: 2px;
    bottom: 18px;
}
/* */

/* Date Time With Accuracy Card */
.date_time_with_accuracy_card {
    width: 80px;
    height: 80px;
    position: relative;
    align-items: center;
    border: 1px solid @gray-light-lighter;
    background: rgba(0, 0, 0, 0.02);
}

.date_time_with_accuracy_card.none {
    display: flex;
    flex-direction: column;
    border: 1px dotted @gray-light-lighter;
    flex-direction: initial;
    background: transparent;
    padding: 4px;
}

.date_time_with_accuracy_card .header {
    position: absolute;
    top: 2px;
    width: 100%;
    font-size: @font-size-12px;
    text-align: center;
}

.date_time_with_accuracy_card .footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    font-size: @font-size-12px;
    color: white;
    background: @app-color;
}

.date_time_with_accuracy_card .main {
    position: absolute;
    font-size: @font-size-36px;
    top: 46%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.date_time_with_accuracy_card.month .main,
.date_time_with_accuracy_card.year .main {
    font-size: @font-size-32px;
}
/* */

/* Number Indicator */
.number-indicator {
    width: 18px;
    height: 18px;
    border-radius: 9px;
    background: @orange-color;
    color: white;
    font-size: @font-size-12px;
    display: flex;
    align-items: center;
}

.number-indicator div {
    width: 100%;
    text-align: center;
}

.number-indicator div.small {
    font-size: @font-size-9px;
}
/* */

/* Tasks */

.task-project-task-group-chevron {
    .font-icon;
    font-size: @font-size-10px;
    margin-left: 2px;
    margin-right: 2px;
}

.btn-task-project-task-group,
.btn-task-project-task-group.ms-Button,
.btn-task-milestone,
.btn-task-milestone.ms-Button {
    .btn-with-icon-item-page;
    color: @app-color;
    text-align: left;
    min-width: 0px;

    &:hover {
        color: @app-color-light;
    }

    &:active {
        color: @app-color-dark;
    }
}

.task-project-icon,
.task-milestone-icon {
    .btn-with-icon-item-page-icon;
    background: @app-color;
}

.btn-task-project-task-group:hover .task-project-icon,
.btn-task-milestone:hover .task-milestone-icon {
    background: @app-color-light;
}

.btn-task-project-task-group:active .task-project-icon,
.btn-task-milestone:active .task-milestone-icon {
    background: @app-color-dark;
}

/* */

/* Unsaved Changes Banner */
.unsaved-changes-banner,
.unsaved-changes-banner.ms-Button {
    background: @orange-color;
    color: white;
    padding: 2px 18px;
    height: 28px;
    width: 100%;

    &:hover {
        background: @orange-color-light;
        color: white;
    }

    &:active {
        background: @orange-color-dark;
        color: white;
    }
}

.unsaved-changes-banner.ms-Button:hover .ms-Button-icon {
    color: white;
}

.unsaved-changes-banner.ms-Button:active .ms-Button-icon {
    color: white;
}

.unsaved-changes-banner .ms-Button-icon {
    color: white;

    &:hover {
        color: white !important;
    }

    &:active {
        color: white !important;
    }
}

.unsaved-changes-banner .ms-Button-label {
    text-wrap: none;
    white-space: nowrap;
}
/* */
