@import "colors.less";
@import "variables.less";

/* Shell */

.main-nav {
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: @gray-dark;
    display: flex;
    flex-direction: column;
}

.main-nav a:hover{
    text-decoration: none;
}

.main-nav ul.minimal {
    width: 46px;
}

.main-nav ul.minimal li a {
    text-overflow: clip;
}

.main-nav ul.minimal li a .project-title {
    display: none;
}

.nav-avatar-wrapper {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
    flex: 0 0 auto;
    transition: all 0.2s ease-out;
}

.nav-profile-details {
    margin-left: 16px;
    margin-top: -4px;
    margin-bottom: -4px;
    color: white;
    flex: 1 1 auto;
}

.main-nav.minimal .nav-avatar-wrapper {
    width: 30px;
    height: 30px;
}

.main-nav.minimal .nav-profile-details {
    display: none;
}

.icon {
    font-family: "Segoe MDL2 Assets", SegoeMDL2;
}

.btn-add-project,
.btn-add-project.ms-Button {
    position: relative;
    top: 0px;
    right: 0px;
    padding-left: 4px;
    padding-right: 4px;
    flex: 0 0 auto;
    .btn-background-app;
}
/* */
